.lc-component.installation-or-group-history-chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 500ms ease;

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        > .box {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 1rem;
            line-height: 1;

            > svg {
                animation: spin 2000ms infinite forwards linear;
            }
        }
    }

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(359deg);
        }
    }
}
