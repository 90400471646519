@use "../../css/variables/colors" as colors;

.lc-component.button {
    position: relative;
    //background: none;
    display: inline-block;
    border: 0;
    color: colors.$text-default;
    padding: 1rem 2rem;
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;

    &.size-small {
        font-size: 1.2rem;
        padding: 0.7rem 1.5rem;
    }

    &.size-medium {
        font-size: 2rem;
    }

    &:after {
        content: '';
        position: absolute;
        height: 3rem;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity .5s ease, background-color .3s ease;
        background-color: red;
    }

    //@each $key, $val in $colors {
    //    &.background-color-#{$key} {
    //        background-color: #{$val};
    //    }
    //}

    @keyframes lc-button-loading-overlay {
        0% {
            right: 100%;
        }

        50% {
            right: 0;
            left: 0;
        }

        100% {
            right: 0;
            left: 100%;
        }
    }

    &.show-loading-animation {
        &:after {
            height: 3px;
            opacity: 1;
            animation: lc-button-loading-overlay 1.2s ease infinite;
        }
    }
}
