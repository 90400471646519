.lc-component.page-wrapper {
    display: flex;
    justify-content: stretch;
    margin: 0 auto;
    flex-grow: 1;
    width: 100%;

    > .inner {
        width: 100%;
    }

    &.content-center-vertical {
        align-items: center;
    }

    &.content-max-width-500 {
        max-width: 500px;
    }

    &.content-max-width-400 {
        max-width: 400px;
    }

    &.content-default-padding {
        > .inner {
            padding: 2rem;
        }
    }
}
