@font-face {
    font-family: 'Overpass';
    src: url('./Overpass-ThinItalic.woff2') format('woff2'),
    url('./Overpass-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('./Overpass-BoldItalic.woff2') format('woff2'),
    url('./Overpass-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('./Overpass-Bold.woff2') format('woff2'),
    url('./Overpass-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('./Overpass-Thin.woff2') format('woff2'),
    url('./Overpass-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('./Overpass-Italic.woff2') format('woff2'),
    url('./Overpass-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('./Overpass-Regular.woff2') format('woff2'),
    url('./Overpass-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('./RobotoMono-BoldItalic.woff2') format('woff2'),
    url('./RobotoMono-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('./RobotoMono-Regular.woff2') format('woff2'),
    url('./RobotoMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('./RobotoMono-Bold.woff2') format('woff2'),
    url('./RobotoMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('./RobotoMono-Italic.woff2') format('woff2'),
    url('./RobotoMono-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

