@use "../variables/colors" as colors;
@use "../variables/character-styles" as character-styles;

.lc-input {
    @include character-styles.input;

    padding: 1.2rem;
    width: 100%;
    background-color: colors.$inputs-background;
    margin: 0;
    min-height: 45px;
    border: 1px colors.$inputs-background solid;
    transition: border-top-color .3s ease;
    border-radius: 0;

    &.type-valuta {
        text-align: right;
    }

    &.type-number {
        text-align: right;
    }

    &.size-small {
        padding: 7px 14px;
        min-height: 40px;
    }

    &.color-white {
        background-color: #fff;
    }

    &::placeholder {
        color: #ccc;
    }

    &.validated-or-edited:invalid,
    &.error {
        border-top-color: #f22;
    }
}
