@use "./colors" as colors;

@mixin default-text {
    font-size: 1.4rem;
    font-family: Overpass, sans-serif;
    line-height: 1.4;
    color: colors.$text-default;
}

@mixin input {
    font-size: 1.4rem;
    font-family: Overpass, sans-serif;
    line-height: 1.6rem;
    color: colors.$text-default;
}


//%heading {
//    font-family: Overpass, sans-serif;
//    font-weight: normal;
//}
//
//%lc-char-style-h1 {
//    @extend %heading;
//    font-size: 2.6rem;
//}
//
//%lc-char-style-h2 {
//    @extend %heading;
//    font-size: 2rem;
//}
//
//%lc-char-style-h3 {
//    @extend %heading;
//    font-size: 1.8rem;
//}
//
//%lc-char-style-input-label {
//    font-family: Overpass, sans-serif;
//    color: #aaa;
//    font-size: 1.4rem;
//}

//%lc-char-style-input-addition {
//    @extend %default-text;
//    color: colors.$grey;
//    line-height: 2rem;
//}
