@use "../../css/variables/colors" as colors;

.lc-component.power-flow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > .power-flow-overview {
        width: 600px;
        height: 600px;
        transform-origin: center center;
        position: relative;
        flex-shrink: 0;

        > canvas.power-lines {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }

        > .item {
            width: 170px;
            height: 170px;
            border-radius: 50%;
            border: 2px #ccc solid;
            position: absolute;
            text-align: center;
            box-shadow: none;
            background-color: #111; // above the power-lines
            transition: box-shadow .3s ease, border-color .3s ease, opacity .3s ease;
            opacity: 0.5;

            display: flex;
            justify-content: center;
            flex-direction: column;

            &.active {
                opacity: 1;

                &.power-yield {
                    border-color: #34ff00;
                    box-shadow: 0 0 30px #34ff00;
                }

                &.power-grid {
                    border-color: #ff0020;
                    box-shadow: 0 0 30px #ff0020;
                }

                &.power-storage {
                    border-color: #4b2fff;
                    box-shadow: 0 0 30px #4b2fff;
                }

                &.power-usage {
                    border-color: #ffb600;
                    box-shadow: 0 0 30px #ffb600;
                }
            }

            &.power-yield {
                top: 0;
                left: 50%;
                margin-left: -85px;
            }

            &.power-grid {
                top: 215px;
                left: 0;
            }

            &.power-usage {
                top: 215px;
                right: 0;
            }

            &.power-storage {
                top: 430px;
                left: 50%;
                margin-left: -85px;
            }

            > .title {
                font-size: 22px;
                position: absolute;
                width: 100%;
                text-align: center;
                margin: 0;
                text-transform: lowercase;

                &.position-top {
                    bottom: calc(100% + 15px);
                }

                &.position-bottom {
                    top: calc(100% + 15px);
                }
            }

            > .sun-power-percentage {
                position: absolute;
                right: -40px;
                top: -10px;
                width: 40px;
                height: 40px;

                > .svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 20;
                }

                > .percentage-canvas-wrapper {
                    position: absolute;
                    left: 7px;
                    right: 7px;
                    top: 7px;
                    bottom: 7px;
                    width: 25px;
                    height: 25px;
                    z-index: 30;

                    > .overlay-canvas {
                        position: relative;
                    }
                }

                > .caption {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    font-size: 10px;
                }
            }

            > .icon {
                margin: 0;

                > .separator {
                    opacity: 0.2;
                    font-size: 2.5rem;
                }

                > .storage-icon-wrapper {
                    display: inline-block;
                    position: relative;
                    margin-bottom: 10px;
                    font-size: 0;

                    > svg {
                        width: 60px;
                        height: 30px;

                        .content {
                            fill: #eee;
                        }
                    }

                    > .percentage-charged-overlay-wrapper {
                        position: absolute;
                        top: 5px;
                        left: 8px;
                        right: 15px;
                        bottom: 5px;
                        background-color: #222;

                        > .visual-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            width: 0;

                            transition: width .3s ease;
                            background-color: #34ff00;
                        }

                        > .numeric-overlay {
                            text-align: center;
                            position: absolute;
                            top: 1px;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            font-size: 12px;
                            mix-blend-mode: difference;
                        }
                    }
                }
            }

            > .value-wrapper {
                font-size: 18px;
                margin: 5px 0 0 0;

                > .value {
                    font-family: "Roboto Mono", sans-serif;
                }
            }

            > .value-caption {
                margin: 0;
                font-size: 14rem;
                color: colors.$grey;
            }
        }

        > p.last-update {
            text-align: center;
            color: rgba(255, 255, 255, 0.2);
            position: fixed;
            right: 0;
            bottom: 10px;
            margin: 0;
            left: 0;

            > .inner {
                background-color: rgba(18, 18, 18, 0.5);
                padding: 10px;
                display: inline-block;

                > .text {

                }

                > .date-time {
                    color: rgba(255, 255, 255, 0.4);
                }

                > .update-now-button {
                    //@extend %lc-char-style-default-text;
                    color: rgba(255, 255, 255, 0.2);
                    display: inline-block;
                    position: relative;
                    background: none;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    text-decoration: underline;

                    > .text {
                        transition: opacity .3s ease;
                    }

                    > .loading-icon {
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        left: 50%;
                        width: 40px;
                        margin-left: -20px;
                        text-align: center;
                    }

                    &.updating {
                        > .text {
                            opacity: 0.2;
                        }

                        > .loading-icon {
                            opacity: 1;
                        }
                    }
                }
            }
        }

    }
}
