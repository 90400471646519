.lc-component.message-box {

    > ul.messages {
        margin: 0;
        padding: 1rem 1rem 1rem 3rem;

        &.errors {
            background-color: red;
        }
    }
}
