.lc-component.page-screen {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    overflow: hidden;

    > .logo-wrapper {
        position: fixed;
        left: 1rem;
        bottom: 1rem;
        opacity: 0.8;

        > img {
            width: 15rem;
            display: block;
        }
    }

    > .live-view {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        padding: 4rem 2rem;

        > * {
            width: 100%;
        }
    }

    > .recent-chart {
        padding-bottom: 1rem;
        position: relative;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        > .inner {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    > .long-term-chart {
        padding-top: 1rem;
        position: relative;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;

        > .inner {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}
