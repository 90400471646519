.lc-page.settings {
    > .input-wrapper {
        display: flex;
        margin: -10px;

        > .input {
            width: 50%;
            padding: 10px;
        }
    }

    > .get-installations-button-wrapper {
        margin: 1rem 0;
    }

    > .installation-groups {
        margin-top: 2rem;

        > .installation-group {
            border: 0.2rem rgba(255, 255, 255, 0.4) solid;
            margin-top: 1rem;
            padding: 1rem;

            > .installations {
                border: 0.2rem rgba(255, 255, 255, 0.4) solid;
                margin-top: 1rem;
                padding: 1rem;
            }
        }
    }
}
