.lc-component.header {
    display: flex;
    justify-content: flex-end;
    background-color: rgba(50, 50, 50, 0.5);
    padding: 1rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: none;

    &.hidden {
        display: none;
    }

    > .app-name {

    }

    > nav {
        a {
            text-decoration: none;
            color: #fff;
            margin-left: 2rem;
            pointer-events: all;

            &.active {
                color: red;
            }
        }
    }

    @keyframes lcp-header-animate-out {
        0% {
            opacity: 1;
            transform: translateY(0);
        }

        100% {
            opacity: 0;
            transform: translateY(-100px);
        }
    }

    &.animate-out {
        animation: lcp-header-animate-out 1s ease forwards;
    }
}

.header-hidden {
    display: none;
}
