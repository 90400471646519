@use "./css/variables/character-styles" as character-styles;
@use "./css/helpers/css-lock" as cssLock;

@import "./fonts";
@import "./css/global/lc-inputs";

html {
    width: 100%;
    height: 100%;
    @include cssLock.lock(6, 30, 300, 4000, 'font-size', 'px'); // for rem :D

    > body {
        @include character-styles.default-text;
        background-color: #000;
        color: #fff;
        margin: 0;
        width: 100%;
        height: 100%;

        > .dg.ac { // dat.gui above anything else
            z-index: 2000;
        }

        * {
            box-sizing: border-box;
        }

        > #root {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            > .app {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}