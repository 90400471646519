$primary: #00b0e6;
$secondary: #1ab36b;

$blue: $primary;
$red: #e32120;
$yellow: #f6a800;
$green: 1$secondary;

$grey: #888888;

$text-default: #ddd;
$inputs-background: #333;
